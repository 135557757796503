//@import url("https://fonts.googleapis.com/css?family=Palanquin|Source+Sans+Pro");
@import url("https://unpkg.com/bootstrap@3.3.7/dist/css/bootstrap.min.css");

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import "bulma/css/bulma.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
//@import "~animate.css/animate.min.css";
//birhanu @import "~ngx-ui-switch/ui-switch.component.scss";
//@import "~ngx-ui-switch/ui-switch.component.scss";

// Colors
$white: #fff !default;
$black: #000 !default;
$red: #d9534f !default;
$orange: #fcb043 !default;
$defaultOrange: #fcb044;
$yellow: #ffd500 !default;
$green: #5cb85c !default;
$blue: #0275d8 !default;
$teal: #5bc0de !default;
$pink: #ff5b77 !default;
$purple: #613d7c !default;

// Grayscale
$gray-dark: #222;
$gray: #333333;
$gray-light: #666;
$gray-lighter: #333333;
$gray-lightest: #eee;

///// Content
$body-font: "Source Sans Pro", sans-serif;
$heading-font: "Palanquin", sans-serif;

html,
body {
  font-family: $body-font;
  padding: 0;
  height: 100%;
}
.wrapper {
  height: 100vh;
  flex-direction: column;
  justify-content: space-around;
}

.inputTextStyle {
  width: 20vw;
  font-family: $body-font;
  border-radius: 10px;
  font-size: 1em;
}

.inputButtonStyle {
  width: 100px;
  height: 35px;
  padding: 0;
  border-radius: 25px;
  color: $white;
  background-color: $orange;
  border: 1px solid $orange;
}

.inputButtonStyle:hover {
  background-color: white;
  border: 1px solid $orange;
  color: $orange;
  font-weight: 500;
}
// .linkStyle:hover {
//   text-decoration: underline;
//   text-decoration-color: $orange;
// }

.linkStyle {
  color: black;
  font-size: 1.2em;
  font-family: $body-font;
}

.linkContainerStyle {
  display: flex;
  padding-right: 30px;
  justify-content: space-around;
  // border: 2px solid red;
}
// .alert-danger {
// background-color: $white;
// color: $red;
// border-color: $white;
// font-weight: bolder;
// }
.errorMessage {
  display: flex;
  // flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;

  .alert-danger {
    margin-top: 10px;
    width: 100%;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    color: orangered;
    background-color: transparent;
  }
}

.form-group {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.container {
  min-width: 350px;
}
.submitStyle {
  border: 1px solid $orange;
  margin-top: 20px;
  width: 100px;
  height: 35px;
  border-radius: 25px;
}
button:active {
  outline: none;
  border: none;
}

.orangeColor {
  color: #fcb043;
}

.testBorder {
  border: 2px red solid;
}

.formStyle {
  background: white;
  margin-left: 40px;
  margin-right: 40px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px 2px orange;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid $white;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
/* mat-drawer-container {
  background-color: red;
  //   height: 100vh;
} */
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
.custom-switches {
  .switch {
    background: white;
    small {
      background: #375e97;
    }
    &.checked {
      background: #fcb044;
    }
  }
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media only screen and (max-width: 720px) {
  .inputTextStyle {
    width: 80vw;
  }
}
